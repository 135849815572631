import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeaderStyles from "../scss/layouts/blog_headers.module.scss"
import styles from "../scss/layouts/content.module.scss"


export default ({ data }) => {
  const pageData = data.markdownRemark;

  return (
    <Layout>
      <SEO title={pageData.frontmatter.title}/>
        <section className={HeaderStyles.blog_header}>
            <div className={HeaderStyles.blog_header__inner}>
                <h1>{pageData.frontmatter.title}</h1>
            </div>
        </section>
        <div className={styles.content}>
          <div className={styles.content__main}>
          <span><strong>Last Updated: </strong>{pageData.frontmatter.last_updated}</span>
            <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
          </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
        last_updated
      }
      html
      fields {
        slug
      }
    }
  }
`
